import { IMAGES } from 'constants/images';
import { STRINGS } from 'constants/strings';

import { ModalStyles as Styled } from './modal.styles';
import { IModalProps } from './modal.typings';

export const Modal = ({
  withBackdrop,
  width,
  children,
  onClose,
}: IModalProps) => {
  return (
    <>
      <Styled.Wrapper width={width}>
        <Styled.Content>
          <Styled.CloseIcon onClick={onClose}>
            <img src={IMAGES.close} alt={STRINGS.altLogo} />
          </Styled.CloseIcon>
          {children}
        </Styled.Content>
      </Styled.Wrapper>
      {withBackdrop && <Styled.BackDrop onClick={onClose} />}
    </>
  );
};
