import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { Loader } from 'components/loader';

import { IPromiseLoaderProps } from './promise-loader.typings';

export const PromiseLoader = ({
  area,
  children,
  isWithoutArea,
}: IPromiseLoaderProps) => {
  const { promiseInProgress } = usePromiseTracker({ area });

  if (!promiseInProgress && !isWithoutArea) {
    return <>{children}</>;
  }

  return <Loader />;
};
