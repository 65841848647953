import { IMAGES } from 'constants/images';

import { MaintenanceStyled as Styled } from './maintenance.styled';

interface IProps {
  message: string;
}

export const Maintenance = (props: IProps) => {
  const { message } = props;

  return (
    <Styled.Root>
      <Styled.Grid>
        <Styled.TextWrapper>
          <Styled.Title>We’re down for scheduled maintenance.</Styled.Title>
          <Styled.Message>{message}</Styled.Message>
        </Styled.TextWrapper>

        <img src={IMAGES.maintenance} />
      </Styled.Grid>
    </Styled.Root>
  );
};
