import { COLORS } from 'styles/colors';

export const HOLLAND_CODE_LETTER_COLORS: Record<string, string> = {
  Realistic: COLORS.greenLight,
  Investigative: COLORS.lightBlue,
  Artistic: COLORS.yellow,
  Social: '#EF60A3',
  Enterprising: '#6C5A5C',
  Conventional: COLORS.violet,
};

export const CATEGORY_HEADINGS = {
  wit: ['WIT', COLORS.greenLight],
  grit: ['GRIT', COLORS.lightBlue],
  fit: ['FIT', COLORS.brilliantRose],
} as const;

export const SKILLS_ORDER = ['Basic', 'Intermediate', 'Advanced'];
