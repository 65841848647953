import { ROUTES } from 'constants/routes';

export const AUTH_MESSAGES = {
  ACTION_AWAIT: 'AWAIT',
  ACTION_SEND_TOKEN: 'SEND_TOKEN',
} as const;

export const DOMAINS_MAP: Record<string, string | undefined> = {
  [ROUTES.gameAuth]: process.env.REACT_APP_GAME,
  [ROUTES.gameNTUAuth]: process.env.REACT_APP_GAME,
  [ROUTES.gameCorpAuth]: process.env.REACT_APP_GAME_CORP,
};
