import React from 'react';

import { Icon, TIcon } from 'components/icon';

import { IButtonProps, ILinkButtonProps } from './button.typings';

import { ButtonStyles } from './button.styles';

export const Button: React.FC<IButtonProps> = (props) => {
  const { title, iconType, isIconRight } = props;

  return (
    <ButtonStyles.Button {...props} isIconRight={isIconRight}>
      {iconType && <Icon type={iconType as TIcon} />}

      <span>{title}</span>
    </ButtonStyles.Button>
  );
};

export const LinkButton = (props: ILinkButtonProps) => {
  const { children, iconType, isIconRight } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <ButtonStyles.Link {...(props as any)} isIconRight={isIconRight}>
      {iconType && <Icon type={iconType as TIcon} />}

      <span>{children}</span>
    </ButtonStyles.Link>
  );
};
