import React from 'react';

import { ITextFieldProps } from 'components/text-field/text-field.typings';

import { InputStyled as Styled } from './input.styles';

export const Input: React.FC<ITextFieldProps> = (props) => {
  const {
    error,
    isAutoCompleteOff,
    type,
    label,
    isSelect,
    isReadOnly,
    isTableReadOnly,
    inputFullWidth,
    className,
    iconNew,
  } = props;

  return (
    <Styled.Root
      className={className}
      inputFullWidth={inputFullWidth}
      error={error}
      label={label}
      isSelect={isSelect}
    >
      <Styled.InputWrapper>
        <Styled.Input
          {...props}
          className={undefined}
          readOnly={isReadOnly || isTableReadOnly}
          autoComplete={isAutoCompleteOff ? 'off' : 'on'}
        />
        {type === 'password' && (
          <Styled.Icon type="shape" isSelect={isSelect} error={error} />
        )}
        {isSelect && <Styled.Icon type="arrowBottom" isSelect={isSelect} />}

        {iconNew}
      </Styled.InputWrapper>

      {error && <Styled.ErrorBlock>{error}</Styled.ErrorBlock>}
    </Styled.Root>
  );
};
