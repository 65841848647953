import styled from 'styled-components';

import { COLORS } from 'styles/colors';
import { FONTS } from 'styles/fonts';

export const MaintenanceStyled = {
  Root: styled.div`
    height: 100vh;
    display: grid;
    align-items: center;
    padding: 16px;
  `,
  Grid: styled.div`
    display: grid;
    align-content: center;
    justify-items: center;
    row-gap: 40px;
  `,
  TextWrapper: styled.div`
    display: grid;
    row-gap: 20px;
    justify-content: center;
  `,
  Title: styled.h2`
    font-family: ${FONTS.family.frutigerBold};
    font-size: ${FONTS.sizes[40]};
    color: ${COLORS.grey};
    text-align: center;
  `,
  Message: styled.p`
    font-family: ${FONTS.family.absideSmooth};
    font-size: ${FONTS.sizes[30]};
    color: ${COLORS.violet};
    text-align: center;
  `,
};
