import { lazy } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { SuspenseWrapper } from 'components/suspense-wrappper';

const Element = lazy(() => import('./canvas-gate'));

export const CanvasGateLazy = (props: RouteComponentProps) => (
  <SuspenseWrapper>
    <Element {...props} />
  </SuspenseWrapper>
);
