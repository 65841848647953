import { ROUTES } from 'constants/routes';
import { createCareerDesignGameToken } from 'services/user.service';

interface IUserCorporateAuthData {
  email: string;
  fullName: string;
}

const moveToStudentGame = async (domain: string) => {
  const token = await createCareerDesignGameToken();

  window.location.href = domain + `/auth?code=${token.data}`;
};

const moveToCorporateGame = (
  domain: string,
  userCorporateAuthData: IUserCorporateAuthData
) => {
  const { email, fullName } = userCorporateAuthData;

  window.location.href = domain + `/log-in?email=${email}&name=${fullName}`;
};

export const moveAndAuthInGame = (
  pathname: string,
  domain: string,
  userCorporateAuthData: IUserCorporateAuthData
) => {
  if (pathname === ROUTES.gameCorpAuth) {
    moveToCorporateGame(domain, userCorporateAuthData);
    return;
  }

  moveToStudentGame(domain);
};
