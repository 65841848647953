import { lazy } from 'react';

import { SuspenseWrapper } from 'components/suspense-wrappper';

const Element = lazy(() => import('./quiz-page'));

export const QuizPageLazy = () => (
  <SuspenseWrapper>
    <Element />
  </SuspenseWrapper>
);
