export const FLEX_QUIZ = {
  careerFlex: {
    id: 'bf4bc167-78a5-4ea9-975b-16d1eebef55d',
    title: 'caas-quiz',
  },
  careerFlexPlus: {
    id: 'bd4bc467-78a5-4ea9-975b-16d1eebef55d',
    title: 'caas-cooperation-quiz',
  },
};
