export const parseTimeInputValue = (value: string) => {
  const cortege = value.split(' ');

  let day = '';
  let hours = '';
  let minute = '';

  cortege.forEach((item) => {
    if (item.includes('d')) {
      day = item.split('d')[0];
    }
    if (item.includes('h')) {
      hours = item.split('h')[0];
    }
    if (item.includes('m')) {
      minute = item.split('m')[0];
    }
  });
  const totalSeconds = String(
    +day * 24 * 60 * 60 + +hours * 60 * 60 + +minute * 60
  );

  return totalSeconds;
};

export const convertToDayHourMinutes = (seconds: string) => {
  const cd = 24 * 60 * 60, // for ms need * 1000
    ch = 60 * 60; // for ms need * 1000

  let d = Math.floor(+seconds / cd),
    h = Math.floor((+seconds - d * cd) / ch),
    m = Math.round((+seconds - d * cd - h * ch) / 60);
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }
  return [d ? d + 'd' : null, h ? h + 'h' : null, m ? m + 'm' : null].join(' ');
};

export const getDateStringFromUtcTimeString = (utcTime: string) => {
  const date = new Date(utcTime);

  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();

  const formattedDate = `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;

  return formattedDate;
};

export const formatSecondsToLocalMessage = (seconds: number): string | null => {
  const targetDate = new Date(seconds * 1000);

  const now = new Date();

  if (targetDate < now) {
    return null;
  }

  const timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
  });
  const formattedTime = timeFormatter.format(targetDate);

  let dateStr = '';
  const tomorrow = new Date(now);
  tomorrow.setDate(tomorrow.getDate() + 1);

  if (targetDate.toDateString() === tomorrow.toDateString()) {
    dateStr = 'tomorrow';
  } else if (targetDate.toDateString() === now.toDateString()) {
    dateStr = 'today';
  } else {
    const dateFormatter = new Intl.DateTimeFormat(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    dateStr = dateFormatter.format(targetDate);
  }

  return `Please come back after ${formattedTime} ${dateStr}`;
};
