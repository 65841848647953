/* eslint-disable @typescript-eslint/no-require-imports */

const getImage = (name: string) => require(`../assets/img/${name}`);

export const IMAGES = {
  companyLogo: getImage('company-logo.svg'),
  productLogo: getImage('product-logo.svg'),
  mainPage: getImage('main-page.jpg'),
  bannerResultMobile: getImage('banner-result-mobile.png'),
  bannerResultLandscape: getImage('banner-result-landscape.png'),
  bannerResultDesktop: getImage('banner-result-desktop.png'),
  concernImage: getImage('concern.png'),
  controlImage: getImage('control.png'),
  curiosityImage: getImage('curiosity.png'),
  confidenceImage: getImage('confidence.png'),
  cooperationImage: getImage('cooperation.png'),
  concernBodyImage: getImage('concern-body.svg'),
  controlBodyImage: getImage('control-body.svg'),
  curiosityBodyImage: getImage('curiosity-body.svg'),
  confidenceBodyImage: getImage('confidence-body.svg'),
  cooperationBodyImage: getImage('cooperation-body.svg'),
  starLow: getImage('star-low.svg'),
  starModerate: getImage('star-moderate.svg'),
  starHigh: getImage('star-high.svg'),
  superPower: getImage('superpower-icon.svg'),
  close: getImage('close.svg'),
  circleBackdrop: getImage('circle-backdrop.png'),
  starTopBackdrop: getImage('star-backdrop.png'),
  starBottomBackdrop: getImage('star-bottom-backdrop.png'),
  ovalBackdrop: getImage('oval-backdrop.png'),
  home: getImage('section-home.svg'),
  assessment: getImage('section-assessment.svg'),
  users: getImage('section-users.svg'),
  userProfile: getImage('user.png'),
  CareerFlex: getImage('career-flex.png'),
  ['CareerFlex+']: getImage('career-flex.png'),
  ['Career Design Game']: getImage('career-design-game.png'),
  ['My Career Adventure']: getImage('my-career-adventure.png'),
  ['Career Design Canvas']: getImage('career-design-canvas.png'),
  word: getImage('word-icon.png'),
  logic: getImage('logic-icon.png'),
  self: getImage('self-icon.png'),
  people: getImage('people-icon.png'),
  music: getImage('music-icon.png'),
  body: getImage('body-icon.png'),
  picture: getImage('picture-icon.png'),
  nature: getImage('nature-icon.png'),
  'Technical Competence': getImage('technical-icon.png'),
  'Managerial Competence': getImage('managerial-icon.png'),
  Autonomy: getImage('autonomy-icon.png'),
  'Job Security': getImage('job-security-icon.png'),
  Lifestyle: getImage('creativity-icon.png'),
  Entrepreneurial: getImage('entrepreneurial-icon.png'),
  'Service/ dedication to a cause': getImage('service-icon.png'),
  'Pure Challenge': getImage('pure-challenge-icon.png'),
  avidAdventures: getImage('avid-adventures-logo.svg'),
  facebook: getImage('facebook-icon.svg'),
  ['CareerFlexBetterQuality']: getImage('careerFlexBetterQuality.png'),
  ['CareerDesignGameBetterQuality']: getImage(
    'сareer-design-game-better-quality.png'
  ),
  ['CareerDesignCanvasBetterQuality']: getImage(
    'career-design-canvas-better-quality.png'
  ),
  ['MyCareerAdventureBetterQuality']: getImage(
    'career-adventury-better-quality.png'
  ),
  emailFooter: getImage('email-footer.png'),
  canvasMedium: getImage('canvas-title-medium.png'),
  backdropDesign: getImage('backdrop-design-game.png'),
  twoMobiles: getImage('two-mobile-phone.png'),
  careerFlexSlide: getImage('career-flex-slide.png'),
  canvasSlide: getImage('canvas-slide.png'),
  canvasGif: getImage('canvas-slide.gif'),
  phonesMain: getImage('phones-main.png'),
  girlMain: getImage('girl-main.png'),
  phoneFlex: getImage('phoneFlex-main.png'),
  maintenance: getImage('maintenance.svg'),
} as const;

export type TImageName = keyof typeof IMAGES;
