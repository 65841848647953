import { ReactNode, useEffect, useState } from 'react';

import { Maintenance } from 'components/maintenance';
import { AppFirebase } from 'services/firebase';
import { formatSecondsToLocalMessage } from 'services/utils';
import { APP_CONFIG } from 'constants/config';

interface IProps {
  children: ReactNode;
}

const MaintenanceLoader = (props: IProps) => {
  const { children } = props;

  const [availableAt, setAvailableAt] = useState<string | null>();

  useEffect(() => {
    const onError = () => setAvailableAt(undefined);

    const { unsubscribe } = AppFirebase.db.subsribeToConfigAppRealtimeData(
      (data) =>
        setAvailableAt(formatSecondsToLocalMessage(data.availableAt.seconds)),
      onError
    );

    return unsubscribe;
  }, []);

  if (availableAt) {
    return <Maintenance message={availableAt} />;
  }

  return <>{children}</>;
};

export const MaintenanceProxy = (props: IProps) => {
  const { children } = props;

  if (APP_CONFIG.isCheckMaintenance) {
    return <MaintenanceLoader>{children}</MaintenanceLoader>;
  }

  return <>{children}</>;
};
