import { lazy } from 'react';

import { SuspenseWrapper } from 'components/suspense-wrappper';

export const Element = lazy(() => import('./canvas-results'));

export const CanvasResultsLazy = () => (
  <SuspenseWrapper>
    <Element />
  </SuspenseWrapper>
);
