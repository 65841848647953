import { MaintenanceProxy } from 'containers/maintenance-controller';

import { AppRouter } from './app.router';

import './app.css';

export const App = () => (
  <MaintenanceProxy>
    <AppRouter />
  </MaintenanceProxy>
);
